import { ref } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import Swal from 'sweetalert2'

export function usePaginatedFetchAndDownload() {
  const mergedData = ref([])
  const isDownloading = ref(false)
  const error = ref(null)

  async function fetchDataPage(url, page) {
    const response = await axiosInstance.get(`${url}&page=${page}`)
    const pageData = response.data
    return pageData
  }

  async function fetchAllPagesAndDownload(url) {
    isDownloading.value = true
    error.value = null
    mergedData.value = []

    try {
      const firstPageData = await fetchDataPage(url, 1)
      mergedData.value = [...firstPageData.data]
      const totalPages = firstPageData.metadata.total_pages
      for (let page = 2; page <= totalPages; page++) {
        const pageData = await fetchDataPage(url, page)
        mergedData.value = [...mergedData.value, ...pageData.data]
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Download Failed',
        text: e.message ?? 'Something went wrong while downloading report.',
        showCloseButton: true,
      })
      error.value = e
    } finally {
      isDownloading.value = false
    }
  }

  return { fetchAllPagesAndDownload, isDownloading, error, mergedData }
}

