<template>
  <div class="p-2">
    <div class="card">
      <div class="flex flex-col">
        <div class="flex justify-end w-full px-4 pt-4 pr-5">
          <button
            @click="handleDownloadReport"
            v-if="!isDownloading"
            class="gap-2 bg-transparent border-0 d-flex align-items-center font-weight-bold"
          >
            <img src="@/assets/img/icons/download_icon.svg" alt="" />
            <span>Download Report</span>
          </button>
          <div v-else class="spinner-border mr-[20px]"></div>
        </div>
        <div class="card-header align-items-center justify-content-between">
          <div class="flex-row-reverse input-group input-group-flush d-flex">
            <input
              @keyup="applySearch"
              v-model.trim="search"
              class="form-control list-search"
              type="search"
              placeholder="Search"
            />
            <span class="border-0 input-group-text">
              <i class="fe fe-search"></i>
            </span>
          </div>
          <div class="col-auto">
            <v-datepicker
              style="width: 100%"
              v-model="filter.startEndDatesFilterArray"
              placeholder="Filter by date"
              range
            ></v-datepicker>
          </div>
        </div>
      </div>

      <b-table
        striped
        hover
        selectable
        responsive
        show-empty
        :items="tableData"
        :fields="computedFields"
        :current-page="currentPage"
        :busy="loading"
        @row-clicked="viewTripDetail($event)"
      >
        <template #table-busy>
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <div class="spinner-border" role="status"></div>
            <p class="mt-2 text-center"><strong>Loading...</strong></p>
          </div>
        </template>

        <template #empty>
          <p class="text-center text-secondary">No records available</p>
        </template>

        <template #cell(sn)="data">
          <span>{{ pageSize * (currentPage - 1) + data.index + 1 }}</span>
        </template>

        <template #cell(date)="data">
          <span>{{ getTripDate(data.item) }}</span>
        </template>

        <template #cell(routeCode)="data">
          <router-link
            v-if="data.item?.route?.id"
            class="sh-page-link"
            :to="{
              name: 'ShowRoute',
              params: { routeId: data.item?.route?.id },
            }"
            >{{ data.item.route.route_code }}
          </router-link>
          (<span>{{
            formatTripStartTime(data.item?.trip_start_time || null)
          }}</span
          >)
        </template>
        <template #cell(route)="data">
          <route-description
            :max-width="250"
            :pickup="data.item.route?.pickup || ''"
            :destination="data.item?.route?.destination || ''"
          >
          </route-description>
        </template>

        <template #cell(driver)="data">
          <span v-if="data.item?.driver?.id"
            ><router-link
              class="sh-page-link"
              :to="{
                name: 'ShowDriver',
                params: { driverId: data.item?.driver?.id },
              }"
            >
              {{
                data.item.driver
                  ? `${data.item.driver.fname} ${data.item.driver.lname}`
                  : data.item.route.driver
                  ? `${data.item.route.driver.fname} ${data.item.route.driver.lname}`
                  : 'N/A'
              }}
            </router-link></span
          >
          <br />
          <span v-if="data.item?.driver && data.item?.driver?.phone">
            <a :href="'tel:' + data.item.driver.phone">{{
              data.item.driver
                ? data.item.driver.phone.replace(/^0/, '+234')
                : 'N/A'
            }}</a>
            <!-- <i class="fa fa-copy" @click="copyPhoneNumberToClipboard(data.item.driver.phone)"></i> -->
          </span>

          <span v-else>N/A</span>
        </template>

        <!-- <template #cell(driver_number)="data"> </template> -->

        <template #cell(passengers)="data">
          <div class="pr-1">
            <span>
              {{
                data?.item?.passengers_count ||
                data?.item?.checkins?.length ||
                0
              }}
              {{
                data?.item?.vehicle?.seats
                  ? '/' + data?.item?.vehicle?.seats
                  : '/' + 0
              }}
            </span>
            <span
              title="Click to view passengers"
              class="view-passenger"
              @click.prevent.stop="viewPassengers(data.item)"
            >
              View
            </span>
          </div>
        </template>

        <template #cell(partner)="data">
          <span
            >{{ data?.item?.vehicle?.partner?.company_name || 'N/A' }}
          </span>
        </template>

        <template #cell(vehicle)="data">
          <span v-if="data.item?.vehicle?.name"
            >{{ data.item?.vehicle?.brand }} {{ data.item?.vehicle?.name }} ({{
              data.item?.vehicle?.registration_number
            }})</span
          >
          <span v-else>N/A</span>
        </template>

        <template #cell(rating)="data">
          <average-rating
            :rating="data.item?.rating || 0"
            :show-trip="false"
          ></average-rating>
        </template>

        <template #cell(actions)="data">
          <slot name="table-actions" v-bind="getActions(data)"></slot>

          <delete-prompt-modal
            :index-key="data.index"
            modal-title="Are you sure you want to cancel this trip ?"
            @close="showCancelTripModalIndex = -1"
            :verifying-delete="false"
            :verification-message="verificationMessage"
            :processing-action="cancellingTrip"
            :show="showCancelTripModalIndex === data.index"
            prompt-text="To cancel this trip, type your password to proceed"
            prompt-key="your password"
            :is-password-type="true"
            :delete-function="
              (passKey) => {
                cancelTrip(data.item, passKey)
              }
            "
          >
          </delete-prompt-modal>

          <delete-prompt-modal
            :index-key="'complete-trip' + data.index"
            modal-title="Are you absolutely sure ?"
            @close="showCompleteTripModal = false"
            :verifying-delete="false"
            verification-message="This action is irreversible and will impact partner revenue!"
            :processing-action="completingTrip"
            :show="showCompleteTripModal"
            :show-prompt-key-label="true"
            prompt-text="If you proceed, this trip will be marked as completed and the partner's account will be credited with the supply cost."
            prompt-key="YES"
            :delete-function="
              () => {
                _completeThisTrip(activeUpcomingTripId)
              }
            "
          >
          </delete-prompt-modal>
        </template>

        <template
          v-if="tripType === 'completed'"
          #cell(completedTripFinancials)="data"
        >
          <div>
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              no-flip
              class="m-2"
            >
              <template #button-content>
                <p class="mt-3" style="font-weight: 900">&#x2026;</p>
              </template>
              <b-dropdown-item
                class="font-weight-bold"
                v-if="userHasPermission('trips007')"
                href="#"
                @click="viewTripFinancials(data.item?.id)"
                >View Financials
              </b-dropdown-item>
              <b-dropdown-item class="font-weight-bold" v-else href="#"
                >UnAuthorised</b-dropdown-item
              >
              <!-- <b-dropdown-item class="font-weight-bold" href="#" @click="copyDriverPhoneNumber(data.item.driver.phone)">Copy driver phone number
              </b-dropdown-item> -->
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="card-footer" v-if="totalRecords">
        <div class="row align-items-center">
          <div class="col">
            <span class="text-dark font-weight-bold">{{ tablePageText }}</span>
          </div>
          <div class="col-auto">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="pageSize"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router/composables'
import { usePaginatedFetchAndDownload } from '@/composables/core/usePaginatedFetchAndDownload'
import { userHasPermission } from '@/composables/core/permissions'
import { useFormattedTripData } from '@/composables/core/useFormattedTripCsvData'
const { formattedCSVData } = useFormattedTripData()
import VueClipboard from 'vue-clipboard2'
import Swal from 'sweetalert2'
const { fetchAllPagesAndDownload, isDownloading, error, mergedData } =
  usePaginatedFetchAndDownload()
const route = useRoute()
const computedTripType = computed(() => {
  switch (route.name) {
    case 'ActiveTrips':
      return 'active'
    case 'UpcomingTrips':
      return 'upcoming'
    case 'CompletedTrips':
      return 'completed'
  }
})
const baseURL = `/v1/trips/${computedTripType.value}?limit=50&metadata=true&sort[created_at]=desc`
const fromParam = ref('')
const toParam = ref('')
watchEffect(() => {
  fromParam.value = route.query.from || ''
  toParam.value = route.query.to || ''
})

const constructApiUrl = computed(() => {
  let url = baseURL
  const params = new URLSearchParams()

  if (fromParam.value) params.append('from', fromParam.value)
  if (toParam.value) params.append('to', toParam.value)

  const queryString = params.toString()
  if (queryString) url += `&${queryString}`

  return url
})

const handleDownloadReport = () => {
  fetchAllPagesAndDownload(constructApiUrl.value).then(() => {
    const csvParams = {
      fieldSeparator: ',',
      filename: `Total Trip report ${
        fromParam.value ? `${fromParam.value} to ${toParam.value}` : ''
      }`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `Trip report from ${fromParam.value} to ${toParam.value}`,
      useTextFile: false,
      useBom: true,
      headers: [
        'Date',
        'Time',
        'Route Code',
        'Pickup',
        'Destination',
        'Partner Name',
        'Driver Name',
        'Driver Phone',
        'Passengers Count',
        'Seats',
        'Vehicle Name',
        'Vehicle Brand',
        'Registration Number',
        'Cost Of Supply',
      ],
    }
    const result = formattedCSVData(mergedData.value)
    if (result.length > 0) {
      const csvExporter = new ExportToCsv(csvParams)
      csvExporter.generateCsv(result)
      Swal.fire({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true,
      })
    }
  })
}
</script>

<script>
import _ from 'lodash'
// import Swal from 'sweetalert2'
import moment from 'moment'
import AverageRating from '@/components/core/AverageRating'
import DeletePromptModal from '@/components/modals/DeletePromptModal'
import { extractErrorMessage } from '@/utils/helpers'
import RouteDescription from '@/components/modules/routes/RouteDescription'
import TableView from '@/mixins/tableview'
import { ExportToCsv } from 'export-to-csv'
import { addToQuery, removeQueryParam } from '@/composables/utils'
import router from '@/router'

export default {
  components: { RouteDescription, DeletePromptModal, AverageRating },
  mixins: [TableView],
  props: {
    requestFunction: Function,
    showActions: {
      type: Boolean,
      default: true,
    },
    showTripDate: {
      type: Boolean,
      default: false,
    },
    isUpcoming: {
      type: Boolean,
      default: false,
    },
    tripType: {
      type: String,
      default: null,
    },
    waitlistCount: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      downloadingReport: false,
      totalRecords: 0,
      loading: false,
      meta_data: {},
      activeUpcomingTripId: null,
      completingTrip: false,
      showCompleteTripModal: false,
      tableData: [],
      fields: [
        {
          key: 'sn',
          label: 'S/N',
        },
        {
          key: 'date',
          label: 'Trip Date ',
        },
        {
          key: 'routeCode',
          label: 'Route code ( Start Time)',
        },
        {
          key: 'route',
          label: 'Route',
        },
        {
          key: 'partner',
          label: 'Partners Name',
        },
        {
          key: 'vehicle',
          label: 'Vehicle Name',
        },
        {
          key: 'driver',
          label: 'Driver',
        },
        {
          key: 'Passengers',
          label: 'Passengers',
        },
      ],
      waitlistFields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'phone',
          label: 'Phone',
        },
        {
          key: 'email',
          label: 'Email',
        },
      ],
      verificationMessage: 'Are you sure you want to end this trip ?',
      showCancelTripModalIndex: -1,
      cancellingTrip: false,
      search: null,
      filter: {
        startEndDatesFilterArray: [],
      },
    }
  },
  watch: {
    currentPage(value) {
      const q = { ...this.$route.query, page: value }
      if (!_.isEqual(q, this.$route.query)) {
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: q,
        })
      }
      this.fetchTripList()
    },
    dateFilterValid() {
      this.fetchTripList(true)
    },
    dateRange(newValue, oldValue) {
      if (this.dateRange[0] && this.dateRange[1]) {
        addToQuery(this.$route, router, {
          from: this.dateRange[0],
          to: this.dateRange[1],
        })
      } else {
        removeQueryParam(this.$route, router, ['from', 'to'])
      }
      this.fetchTripList(true)
    },
    search() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (this.search) {
          addToQuery(this.$route, router, { search: this.search })
        } else {
          removeQueryParam(this.$route, router, ['search'])
        }
        this.fetchTripList(true)
      }, 600)
    },
  },

  created() {
    if (this.showActions) {
      this.fields.push({ key: 'actions', value: 'Actions' })
    }
    if (this.tripType === 'completed') {
      this.fields.splice(-1, 0, { key: 'rating', label: 'Average Rating' })
      this.fields.splice(-1, 0, {
        key: 'completedTripFinancials',
        label: 'Action',
      })
    }
  },
  computed: {
    computedFields() {
      if (!this.isUpcoming) {
        return this.fields.filter((field) => !field.isUpcoming)
      } else {
        return this.fields
      }
    },
    tableColumns() {
      if (this.showTripDate) {
        return this.fields
      } else {
        return this.fields.filter((column) => column.label !== 'Trip Date')
      }
    },
    dateProp() {
      return this.tripType === 'upcoming' ? 'trip_date' : 'created_at'
    },

    dateRange() {
      // return this.filter.startEndDatesFilterArray.map((date) =>
      //   moment(date).format('YYYY-MM-DD')
      // )
      if (
        !this.filter.startEndDatesFilterArray ||
        !this.filter.startEndDatesFilterArray[0]
      )
        return [null, null]
      return this.filter.startEndDatesFilterArray.map((date) =>
        moment(date).format('YYYY-MM-DD')
      )
    },
    dateFilterValid() {
      return (
        this.filter.startEndDatesFilterArray.length &&
        this.filter.startEndDatesFilterArray.filter((date) => !!date).length > 0
      )
    },
  },
  mounted() {
    // this.parseQueryParams()
    this.checkForFilters()
    this.emitInterface()
  },
  methods: {
    copyDriverPhoneNumber(phone) {
      VueClipboard.toClipboard(phone)
        .then(() => {
          // Clipboard copy success
          alert('Phone number copied to clipboard')
        })
        .catch(() => {
          // Clipboard copy failed
          alert('Failed to copy phone number to clipboard')
        })
    },
    checkForFilters() {
      const q = this.$route.query
      if (q.search) this.search = q.search
      if (q.from && q.to)
        this.filter.startEndDatesFilterArray = [
          new Date(q.from),
          new Date(q.to),
        ]
      this.fetchTripList()
    },
    getTripDate(item) {
      if (item) {
        if (item.trip_date) {
          return item.trip_date
        }

        return moment(item?.trip_start_time || item?.start_trip).format(
          'DD-MM-YYYY'
        )
      }
    },
    getTripCompletionDate(item) {
      if (item) {
        return moment(item?.end_trip).format('DD-MM-YYYY')
      }
    },
    formatTripStartTime(startTime) {
      if (startTime) {
        return moment(startTime).subtract(60, 'minutes').format('LT')
      }
      return 'N/A'
    },
    viewTripFinancials(tripId) {
      this.$router.push({
        name: 'TripFinancials',
        params: { tripId: tripId, tripType: 'completed' },
      })
    },
    viewTripDetail(trip, routeName = null) {
      if (this.tripType && this.tripType === 'active') {
        this.$store.dispatch('setSelectedTripData', trip)
        this.$router.push({
          name: routeName || 'TripDetails',
          params: { tripId: '__' + trip.id, tripType: 'active' },
        })
      } else if (this.tripType && this.tripType === 'upcoming') {
        this.$store.dispatch('setSelectedTripData', trip)
        this.$router.push({
          name: routeName || 'TripDetails',
          params: { tripId: '__' + trip.id, tripType: 'upcoming' },
        })
      } else if (this.tripType && this.tripType === 'completed') {
        this.$store.dispatch('setSelectedTripData', trip)
        this.$router.push({
          name: routeName || 'TripDetails',
          params: { tripId: '__' + trip.id, tripType: 'completed' },
        })
      } else {
        this.$router.push({
          name: routeName || 'TripDetails',
          params: { tripId: trip.id },
        })
      }
    },
    async fetchTripList() {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        const data = await this.requestFunction({
          page: this.currentPage,
          limit: this.pageSize,
          search: this.search,
          from: this.dateRange[0],
          to: this.dateRange[1],
        })
        this.tableData = data.data
        this.meta_data = data.meta_data
        this.totalRecords = data.meta_data.total
      } catch (e) {
      } finally {
        this.loading = false
      }
    },

    refreshTable(searchEnabled = false) {
      if (!searchEnabled) {
        this.search = ''
      }
      if (this.currentPage === 1) {
        this.fetchTripList()
      } else {
        this.currentPage = 1
      }
    },
    async startTrip(trip) {
      const time = trip.route.itineraries[0]?.trip_time
      const tripTimeMinus10Minutes = moment(
        `${trip.trip_date} ${time}`
      ).subtract(10, 'minutes')

      if (!moment().isAfter(tripTimeMinus10Minutes)) {
        this.$swal({
          icon: 'info',
          title: 'Trip can\'t be started',
          text: 'You can only start a trip 10 minutes before start time or after',
          showCloseButton: true,
        })
        return
      }
      Swal.fire({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to start this trip?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .post('/v1/trips', {
              route_id: trip.route.id,
              pickup_coordinate: trip.route.pickup_coordinate,
              driver_id: trip.route.driver.id,
              route_itinerary_id: trip.route_itinerary_id,
              trip_date: trip.trip_date,
            })
            .then(() => {
              this.fetchTripList()
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occurred, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Trip started successfully',
            showCloseButton: true,
          })
        }
      })
    },
    cancelTrip(trip, passKey) {
      this.cancellingTrip = true
      this.axios
        .delete(`/v1/upcoming-trips/${trip.id}`, {
          data: {
            password: passKey,
          },
        })
        .then(() => {
          this.fetchTripList()
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Trip cancelled successfully',
            showCloseButton: true,
          })
          this.cancellingTrip = false
          this.showCancelTripModalIndex = -1
        })
        .catch((error) => {
          this.cancellingTrip = false
          const msg = extractErrorMessage(
            error,
            'An error occurred, please try again.'
          )
          this.$swal().showValidationMessage(msg)
        })
    },
    async endTrip(trip) {
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to end this trip?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/trips/${trip.id}`, {
              route_id: trip.route_id,
              destination_coordinate: trip.destination_coordinate
                ? trip.destination_coordinate
                : '6.5135466666661115, 3.3668908333333337',
            })
            .then(() => {
              this.fetchTripList()
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occurred, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Trip ended successfully',
            showCloseButton: true,
          })
        }
      })
    },
    viewPassengers(tripData) {
      this.viewTripDetail(tripData, 'TripPassengers')
    },
    emitInterface() {
      this.$emit('trip-history-interface', {
        refreshTable: () => this.refreshTable(!!this.search),
      })
    },
    openCancelTripModal(message) {
      this.verificationMessage = message
    },
    _completeThisTrip(upcomingTripId) {
      this.completingTrip = true
      return this.axios
        .post(`v1/upcoming-trips/${upcomingTripId}/trips`)
        .then(() => {
          this.fetchTripList()
          this.showCompleteTripModal = false
          this.activeUpcomingTripId = null
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Trip ended successfully',
            showCloseButton: true,
          })
        })
        .catch((error) => {
          const msg =
            error.response && error.response.data
              ? error.response.data.message
              : 'An error occurred, please try again.'

          this.$swal().showValidationMessage(msg)
        })
        .finally(() => {
          this.completingTrip = false
        })
    },
    getActions(data) {
      return {
        endTrip: () => {
          this.endTrip(data.item)
        },
        startTrip: () => {
          this.startTrip(data.item)
        },
        openDelete: (message) => {
          this.showCancelTripModalIndex = data.index
          this.openCancelTripModal(message)
        },

        completeThisTrip: (upcomingTripId) => {
          const vm = this
          if (vm.showCompleteTripModal) return false
          vm.activeUpcomingTripId = upcomingTripId
          vm.showCompleteTripModal = true
          return true
        },
        data: data,
      }
    },
    applySearch(event) {
      if (event.key === 'Enter' || event.keyCode === 13) {
        const q = { ...this.$route.query, q: this.search }
        if (!_.isEqual(q, this.$route.query)) {
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query: q,
          })
        }

        this.refreshTable(true)
      } else if (!this.search) {
        this.refreshTable(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/b-table';

.view-passenger {
  border: 1px solid;
  padding: 0.25rem 0.25rem;
  border-radius: 1rem;
  cursor: pointer;
  font-weight: 500;
  color: #109352;
  font-size: 0.8rem;
}
</style>

