import moment from 'moment'
export const useFormattedTripData = () => {
  const formattedCSVData = (data) => {
    return data.map((trip) => {
        return {
            date: moment.utc(trip?.trip_start_time).format('Do MMMM, YYYY') ?? 'N/A',
            time: moment.utc(trip?.trip_date_time).format('h:mm A') ?? 'N/A',
            'route_code': trip?.route?.route_code ?? 'N/A',
            pickup: trip?.route?.pickup || 'N/A',
            destination: trip?.route?.destination,
            'partner_name': trip?.vehicle?.partner?.company_name ?? 'N/A',
            'driver_name': trip?.driver ? `${trip?.driver?.fname} ${trip?.driver?.lname}` : trip?.route?.driver ? `${trip?.route?.driver?.fname} ${trip?.route?.driver?.lname}` : 'N/A',
            'driver_phone': trip?.driver?.phone || 'N/A',
            'passengers_count': trip?.passengers_count || 0,
            seats: trip?.vehicle?.seats || 0,
            'vehicle_name': trip?.vehicle?.name || 'N/A',
            'vehicle_brand': trip?.vehicle?.brand || 'N/A',
            'registration_number': trip?.vehicle?.registration_number || 'N/A',
            'cost_of_supply': trip?.cost_of_supply || 0
        }
    })
  }
  return { formattedCSVData }

}

